import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarContainer from './Navbar/Navbar';
import Hero from './Hero/Hero';
import AboutUs from './AboutUs/AboutUs';
import Services from './Services/Services';
import Hapat from './Hapat/Hapat';
import Team from './Team/Team';
import Footer from './Footer/Footer';
import Contact from './Contact/Contact';

function App() {
  return (
    <div id="kreu">
      <NavbarContainer />
      <Hero />
      <AboutUs />
      <Services />
      <Hapat />
      <Contact />
      <Team />
      <Footer />
    </div>
  );
}

export default App;
