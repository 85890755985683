import { Col, Container, Row } from "react-bootstrap"
import "./team.scss"
import Image2 from "../assets/images/ervin_uruci.jpeg"
import Image1 from "../assets/images/ambi_gjoka1.jpg"
import Image3 from "../assets/images/sergen_skenderi.JPEG"

const Team = () => {
    return (
        <Container id="ekipi">
            <div className="titleSection">
            <h2>Ekipi yne</h2>
            </div>
            <Row className="quota">
                <p><cite className="quotaSentence">"Puna në grup e bën ëndrrën të funksionojë."</cite> - Bang Gae</p>
            </Row>
            <Row>
                <p>Kjo thënie përfaqëson më së miri shpirtin e ekpit që karakterizon ItalStudy-in.
Secili anëtarë krahas punës që bën për të lehtësuar studentët shqiptarë që të bëjnë realitet ëndrrën e tyre për të studiuar jashtë, ‘pasuron’ ekipin duke vënë në dispozicion eksperiencat e tyre si ish studentë në Itali dhe kontaktet e shumta që kanë lidhur ndër vite me institucionet dhe bizneset. </p>
            </Row>
            <Row>
            <Col sm={4}>
                <div className="our-team">
                    <div className="pic">
                        <img src={Image1} />
                    </div>
                    <h3 className="title">Ambi Gjoka</h3>
                    <span className="post">CEO & FOUNDER</span>
                    <ul className="social">
                        <li><a href="#" className="fa fa-facebook"></a></li>
                        <li><a href="#" className="fa fa-twitter"></a></li>
                        <li><a href="#" className="fa fa-google-plus"></a></li>
                        <li><a href="#" className="fa fa-linkedin"></a></li>
                    </ul>
                </div>
            </Col>
     
            <Col sm={4}>
                <div className="our-team">
                    <div className="pic">
                        <img src={Image2} />
                    </div>
                    <h3 className="title">Ervin Uruci</h3>
                    <span className="post">Brand Positioning Specialist</span>
                    <ul className="social">
                        <li><a href="#" className="fa fa-facebook"></a></li>
                        <li><a href="#" className="fa fa-twitter"></a></li>
                        <li><a href="#" className="fa fa-google-plus"></a></li>
                        <li><a href="#" className="fa fa-linkedin"></a></li>
                    </ul>
                </div>
            </Col>
    
            <Col sm={4}>
                <div className="our-team">
                    <div className="pic">
                        <img src={Image3} />
                    </div>
                    <h3 className="title">Sergen Skenderi</h3>
                    <span className="post">Web Designer</span>
                    <ul className="social">
                        <li><a href="#" className="fa fa-facebook"></a></li>
                        <li><a href="#" className="fa fa-twitter"></a></li>
                        <li><a href="#" className="fa fa-google-plus"></a></li>
                        <li><a href="#" className="fa fa-linkedin"></a></li>
                    </ul>
                </div>
            </Col>

            {/* <Col>
                <div className="our-team">
                    <div className="pic">
                        <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1600" />
                    </div>
                    <h3 className="title">Kristiana</h3>
                    <span className="post">Web Designer</span>
                    <ul className="social">
                        <li><a href="#" className="fa fa-facebook"></a></li>
                        <li><a href="#" className="fa fa-twitter"></a></li>
                        <li><a href="#" className="fa fa-google-plus"></a></li>
                        <li><a href="#" className="fa fa-linkedin"></a></li>
                    </ul>
                </div>
            </Col> */}
            </Row>
        </Container>
    )
}

export default Team