import { Col, Container, Row } from "react-bootstrap";
import aplikimPerDegen from "../assets/images/aplikim_per_degen.jpg"
import aplikimPerKonvikt from "../assets/images/aplikim_per_konvikt.jpg"
import aplikimPerVise from "../assets/images/aplikim_per_vise.jpg"
import aplikimPerQendrim from "../assets/images/aplikim_per_qendrim.jpg"
import sherbimePerkthimi from "../assets/images/sherbime_perkthimi.jpg"
import sherbimeKurse from "../assets/images/sherbime_kurse.jpg"
import "../App.scss"
import "./services.scss"

const Services = () => {
    return (
        <div id="services" className="section">
            <Container>
            <div className="titleSection">
            <h2>Shërbimet tona</h2>
            </div>
            <Row>
              <Col className="serviceCol" md={6}>
                <div className="img-container">
                    <img
                    src={aplikimPerDegen}
                    alt="meditation"
                    />
                    <p className="centered">Aplikim për Universitet</p>
                    <p className="centeredDesc">Ne ndihmojmë studentët për të identifikuar Universitetin, kryerjen e aplikimeve dhe lundrimin në tërësi i procedurave komplekse të pranimit në Universitet.</p>
                </div>
              </Col>
              <Col className="serviceCol" md={6}>
                <div className="img-container">
                    <img
                    src={aplikimPerKonvikt}
                    alt="meditation"
                    />
                    <p className="centered">Aplikim për bursë studimi dhe konvikt</p>
                    <p className="centeredDesc">asistojmë dhe aplikojmë për bursa studimi ekselence dhe ekonomike</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="serviceCol" md={6}>
                <div className="img-container">
                    <img
                    src={aplikimPerVise}
                    alt="meditation"
                    />
                    <p className="centered">Aplikim për vizën e studentit</p>
                    <p className="centeredDesc">asistojmë dhe aplikojmë për vizën e studentit</p>
                </div>
              </Col>
              <Col className="serviceCol" md={6}>
                <div className="img-container">
                    <img
                    src={aplikimPerQendrim}
                    alt="meditation"
                    />
                    <p className="centered">Aplikim për lejen e qendrimit</p>
                    <p className="centeredDesc">ndihmojmë studentët për të përgatitur dosjen e nevojshme për marrjen e lejes së qendrimit italiane.</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="serviceCol" md={6}>
                <div className="img-container">
                    <img
                    src={sherbimePerkthimi}
                    alt="meditation"
                    />
                    <p className="centered">Shërbime përkthimi</p>
                    <p className="centeredDesc">Rekomandojme përkthyesit më të mirë për realizimin e shërbimeve të përkthimit të dokumentacionit të nevojshëm</p>
                </div>
              </Col>
              <Col className="serviceCol" md={6}>
                <div className="img-container">
                    <img
                    src={sherbimeKurse}
                    alt="meditation"
                    />
                    <p className="centered">Kurse të gjuhëve të huaja</p>
                    <p className="centeredDesc">rekomandojmë studio profesionale dhe serioze të kursëve për gjuhët e huaja</p>
                </div>
              </Col>
            </Row>
            </Container>
        </div>
    )
}

export default Services;