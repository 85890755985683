import { Col, Container, Row } from "react-bootstrap"
import "./aboutus.scss"
import AboutUsImage from "../assets/images/library.jpg"

const AboutUs = () => {
    return (
        <div id="aboutUs" className="about">
        <Container>
      <Row>
        <Col className="imageAbout"><img src={AboutUsImage} /></Col>
        <Col>
            <div className="contentAbout">
                <div className="titleSection">
                    <h2>Rreth nesh</h2>
                </div>
                <div className="descriptionAbout">
                    <p>
                    ItalStudy është agjensi leader dhe e specializuar vetëm për konsulenca në studimet universitare në Itali. Në ndryshim nga agjensitë e tjera që ofrojnë konsulenca për shumë shtete, ItalStudy është themeluar bazuar në përvojën personale të Ambi Gjokës dhe Nikita Imaj të cilët kanë përfunduar studimet për ekonomi në Milano, Universiteti i Milanos. 
Çdo student ndiqet nga konsulenti hap pas hapi nga aplikimi për në degën që dëshiron deri në akomodimin në Itali. Pasi përzgjidhet dega që i përshatet studentit, konsulenti rekomandon krahinën/qytetin që ka universitetin publik ose privat më të mirë në atë fushë dhe që i mundëson studentit bursë studimi. 
Krahas bursës, bazur në njohjet e detajuar të tregut të punës në itali, studenti këshillohen dhe për mundësitë që kanë për të punuar me kohë të pjesshme.
Studentët shqiptarë e dinë që një diplomë europiane është garanci për një karrierë të suksesshme!
                    </p>
                </div>
            </div>
        </Col>
      </Row>
    </Container>
        </div>
    )
}

export default AboutUs