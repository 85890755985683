import "./hapat.scss"
import { Container, Row , Col } from "react-bootstrap";
import Line_Step from "../assets/images/line_step1.svg"

const Hapat = () => {
    return (
        <div id="aplikim" className="section">
            <Container>
            <div className="titleSection">
            <h2>Hapat per aplikim</h2>
            </div>
            <Row className="firstRowHapat">
                <Col md={6} className="listCol">
                  <div className="numberCircle">1</div>
                  <h4>Përpara mbërritjes në Universitet</h4>
                </Col>
                <Col md={6}>
                 <ol className="gradient-list">
                 <li>Konsultë dhe dhënie e informacioneve të domosdoshme për Universitetin ose Institucionin arsimor për të cilin jeni të interesuar.</li>
                    <li>Ndihma për të realizuar procesin e aplikimit në Universitet.</li>
                    <li>Aplikim per bursa studimi.</li>
                    <li>Ndihma për të plotesuar aplikimin për vizë.</li>
                </ol>
                </Col>
            </Row>
            <Row><img  className="lineStep" src={Line_Step}></img></Row>
            <Row className="reverseOnMobile">
                <Col md={6}>
                <ol className="gradient-list">
                   <li>Ndihmë për regjistrimin përfundimtar në Universitet.</li>
                    <li>Asistencë për pergatitjen e dosjes për aplikimin e lejes së qendrimit.</li>
                    <li>Asistencë në përzgjedhjen e siguracionit shëndetësor, hapjen e llogarisë bankare dhe informacione të tjera të domosdoshme.</li>
                </ol>
                </Col>
                <Col md={6} className="listCol">
                <h4>Pas mbërritjes në Itali</h4>
                  <div className="numberCircle">2</div>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default Hapat;