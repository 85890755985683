import { Col, Container, Row } from "react-bootstrap"
import "./contact.scss"

const Contact = () => {
    return (
    <div id="contact" className="section">
        <Container className="contactSection">
            <div className="titleSection contactUs">
            <h2>Na kontaktoni</h2>
            </div>
            <Row>
                <p className="contactDesc">Prenoto tani  një telefonatë/takim falas me një konsulent dhe verifiko shanset e tua për të studiuar në Itali.</p>
            </Row>
            <Row>
                <Col md={4}>
                   <a href="tel:+355692650026">
                        <div className="contaktCol">
                            <div className="contactIcon">
                            <i className="fa fa-phone"></i>
                            </div>
                            <div>
                            <p><span className="titleContact">Celular</span>: +355692650026</p>
                            </div>
                    </div>
                    </a>
                </Col>
                <Col md={4}>
                  <a href = "mailto: contact@italstudy.al">
                    <div className="contaktCol">
                        <div className="contactIcon">
                            <i className="fa fa-envelope"></i>
                        </div>
                        <div>
                        <p><span className="titleContact">Email</span>:contact@italstudy.al</p>
                        </div>
                    </div>
                   </a>
                </Col>
                <Col md={4}>
                  <a href = "https://www.instagram.com/italstudy/?hl=en" target="_blank">
                    <div className="contaktCol">
                        <div className="contactIcon">
                        <i className="fa fa-instagram"></i>
                        </div>
                        <div>
                        <p><span className="titleContact">Instagram</span>: ital_study</p>
                        </div>
                    </div>
                  </a>
                </Col>
            </Row>
        </Container>   
     </div>
    )
}

export default Contact