import VideoPlayer from "react-background-video-player";
import StudentsVideo from "../assets/videos/students_video.mp4"

const Hero = () => {
    return (
        <div>
        <VideoPlayer
        className="video"
        src={StudentsVideo}
        autoPlay={true}
        muted={true}
        style={{height:"1000px"}}
      />
      </div>
    )
}

export default Hero;