import "./footer.scss";
const Footer = () => {
    return (
    <div className="footer-basic">
        <footer>
            <div className="social"><a href="#"><i className="fa fa-facebook"></i></a><a href="#"><i className="fa fa-twitter"></i></a><a href="#"><i className="fa fa-google-plus"></i></a><a href="#"><i className="fa fa-linkedin"></i></a></div>
            <ul className="list-inline">
                <li className="list-inline-item"><a href="#kreu">Kreu</a></li>
                <li className="list-inline-item"><a href="#aboutUs">Rreth nesh</a></li>
                <li className="list-inline-item"><a href="#aplikim">Aplikim</a></li>
                <li className="list-inline-item"><a href="#ekipi">Ekipi</a></li>
                <li className="list-inline-item"><a href="#">Politika e Privatësisë</a></li>
            </ul>
            <p className="copyright">Italstudy © 2023</p>
        </footer>
    </div>
    )
}

export default Footer