import { Container, Nav, Navbar } from "react-bootstrap";
import "./navbar.scss"
import Logo from "../assets/images/logo.svg";

function NavbarContainer() {
  return (
    <Navbar bg="white" fixed="top" expand="lg">
        <Container>
          <Navbar.Brand href="#kreu"><img
              className="d-inline-block align-top imageLogo"
              alt="React Bootstrap logo" src={Logo} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <div className="topNavlink"><Nav.Link href="#aboutUs">Rreth nesh</Nav.Link></div>
            <div className="topNavlink"><Nav.Link href="#services">Shërbimet</Nav.Link></div>
            <div className="topNavlink"><Nav.Link href="#aplikim">Aplikim</Nav.Link></div>
            <div className="topNavlink"><Nav.Link href="#ekipi">Ekipi</Nav.Link></div>
          </Nav>
          </Navbar.Collapse>
        </Container>
    </Navbar>
  );
}

export default NavbarContainer;
